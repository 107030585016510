import {createSlice} from '@reduxjs/toolkit';
import type {PayloadAction} from '@reduxjs/toolkit';

export interface TranslationsState {
    locale: string;
    translations: { [key: string]: string; } | null;
}

const initialState: TranslationsState = {
    locale: process.env.DEFAULT_LOCALE || '',
    translations: null
}

const translationsSlice = createSlice({
    name: 'translations',
    initialState,
    reducers: {
        initializeTranslationState(state, action: PayloadAction<TranslationsState>) {
            return action.payload;
        },
        setLocale(state, action: PayloadAction<string>) {
            state.locale = action.payload;
        },
        setTranslations(state, action: PayloadAction<{ [key: string]: string; }>) {
            state.translations = action.payload;
        }
    }
});

export const {setLocale, setTranslations, initializeTranslationState} = translationsSlice.actions;
export default translationsSlice.reducer;
