import {createSlice} from '@reduxjs/toolkit';
import type {PayloadAction} from '@reduxjs/toolkit';

export interface LayoutState {
    searchOverlayVisible: boolean;
    backdropVisible: boolean;
    mobileNavigationVisible: boolean;
}

const initialState: LayoutState = {
    searchOverlayVisible: false,
    backdropVisible: false,
    mobileNavigationVisible: false,
}

const layoutSlice = createSlice({
    name: 'layout',
    initialState,
    reducers: {
        setSearchOverlayVisible(state, action: PayloadAction<boolean>) {
            state.searchOverlayVisible = action.payload;
            state.backdropVisible = action.payload;
            state.mobileNavigationVisible = false;
        },
        setBackdropVisible(state, action: PayloadAction<boolean>) {
            state.backdropVisible = action.payload;
        },
        setMobileNavigationVisible(state, action: PayloadAction<boolean>) {
            state.mobileNavigationVisible = action.payload;
            state.searchOverlayVisible = false;
            state.backdropVisible = false;
        },
    }
});

export const {
    setSearchOverlayVisible,
    setBackdropVisible,
    setMobileNavigationVisible
} = layoutSlice.actions;
export default layoutSlice.reducer;
