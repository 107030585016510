import {configureStore} from '@reduxjs/toolkit';

import translationsReducer from './slices/translationsSlice';
import layoutReducer from './slices/layoutSlice';


export const store = configureStore({
    reducer: {
        translations: translationsReducer,
        layout: layoutReducer,
    }
});

export const makeStore = () => {
    return store;
}

export type AppStore = ReturnType<typeof makeStore>
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
